<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Editar avatar
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Modificar Imagen</span>
        </v-card-title>
        <v-card-text>
          Selecionar imagen
          <v-container>
            <v-file-input
              v-if="!isLoading"
              v-model="newImage"
              accept="image/png, image/jpeg, image/bmp"
              placeholder="Pick an avatar"
              prepend-icon="mdi-camera"
              label="Avatar"
            ></v-file-input>
            <span v-else>Subiendo ....</span>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="isLoading"
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn :disabled="isLoading" color="primary" @click="updateAvatar">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    newImage: [],
    dialog: false,
    isLoading: false
  }),
  methods: {
    ...mapActions({
      updateContactByIdStore: "contacts/updateContactById"
    }),
    async updateAvatar() {
      this.isLoading = true;
      const actualData = this.$store.state.userData;
      const url =
        "https://api.cloudinary.com/v1_1/capital28-investments/upload";
      try {
        // Preparando imagen
        let formData = new FormData();
        formData.append("file", this.newImage);
        formData.append("upload_preset", "brokers_profile_image");
        // subir imagen a cloudinary
        const data = await fetch(url, { method: "POST", body: formData });
        const json = await data.json();
        // Actuaizar url en la DB
        const media = {
          featured_image: {
            src: json.secure_url,
            src_avatar: json.secure_url,
            cloudinary_id: actualData.name
          }
        };
        const info = {
          role: actualData.role,
          data: {
            _id: actualData._id,
            user_id: actualData.user_id,
            table: actualData.role,
            role: actualData.role,
            name: actualData.name,
            email: actualData.email,
            phone: actualData.phone,
            media: media
          }
        };
        this.updateContactByIdStore(info).then(() => {
          // solo actualiza la imagen
          this.$store.state.userData.media = media;
          localStorage.setItem("user_image", json.secure_url);
          this.isLoading = false;
          this.dialog = false;
          this.newImage = [];
        });
      } catch (e) {
        this.isLoading = false;
        this.dialog = false;
        this.newImage = [];
        this.$snotify.error(`Ocurrio un erro al cambiar la imagen.`, `Error!`, {
          closeOnClick: true,
          timeout: 4000
        });
      }
    }
  }
};
</script>
